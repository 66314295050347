import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
// import axios from '../../utils/axios';
//
// import { dispatch } from '../store';
import {
  changeColumnOrder,
  changeColumns,
  createBoard,
  createCard,
  deleteCard,
  getCurrentBoard,
} from '../../services/KanbanService';

// ----------------------------------------------------------------------

// function objFromArray(array, key = 'id') {
//   console.log('array', array);
//   if (Object.keys(array).length === 0) {
//     return [];
//   }
//   return array?.reduce((accumulator, current) => {
//     accumulator[current[key]] = current;
//     return accumulator;
//   }, {});
// }

const initialState = {
  isLoading: false,
  error: null,
  usersList: [],
  clientsList: [],
  currentCard: {},
  currentBoard: {
    cards: {},
    columns: {
      cero: {
        cardIds: [],
        id: 'cero',
        name: 'Prospecto',
      },
      one: {
        cardIds: [],
        id: 'one',
        name: 'Orden de venta',
      },
      two: {
        cardIds: [],
        id: 'two',
        name: 'Venta',
      },
      three: {
        cardIds: [],
        id: 'three',
        name: 'Entregado',
      },
    },
    columnOrder: ['cero', 'one', 'two', 'three'],
  },
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    setBoard(state, action) {
      state.currentBoard = action.payload;

      // state.isLoading = false;
      // const board = action.payload;
      // const cards = objFromArray(board?.cards);
      // const columns = objFromArray(board?.columns);
      // const columnOrder = board?.columnOrder;
      // state.currentBoard = {
      //   cards,
      //   columns,
      //   columnOrder,
      // };
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.currentBoard.columns = {
        ...state.currentBoard.columns,
        [newColumn.id]: newColumn,
      };
      state.currentBoard.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.currentBoard.columns = columns;
    },

    persistColumn(state, action) {
      state.currentBoard.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId, currentBoard } = action.payload;

      // console.log('card', card);

      if (!currentBoard.cards) {
        state.currentBoard = {
          ...currentBoard,
          cards: {},
        };
      }
      if (!currentBoard.columns[columnId].cardIds) {
        state.currentBoard = {
          ...state.currentBoard,
          columns: {
            ...state.currentBoard.columns,
            [columnId]: {
              ...state.currentBoard.columns[columnId],
              cardIds: [],
            },
          },
        };
      }

      state.currentBoard.cards[card.id] = card;
      state.currentBoard.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      state.currentBoard.columns[columnId].cardIds = state.currentBoard.columns[columnId].cardIds.filter(
        (id) => id !== cardId
      );
      state.currentBoard.cards = omit(state.currentBoard.cards, [cardId]);
    },

    // UPDATE COLUMN
    // updateColumnSuccess(state, action) {
    //   const column = action.payload;

    //   state.isLoading = false;
    //   state.currentBoard.columns[column.id] = column;
    // },

    // DELETE COLUMN
    // deleteColumnSuccess(state, action) {
    //   const { columnId } = action.payload;
    //   const deletedColumn = state.currentBoard.columns[columnId];

    //   state.isLoading = false;
    //   state.currentBoard.columns = omit(state.currentBoard.columns, [columnId]);
    //   state.currentBoard.cards = omit(state.currentBoard.cards, [...deletedColumn.cardIds]);
    //   state.currentBoard.columnOrder = state.currentBoard.columnOrder.filter((c) => c !== columnId);
    // },

    // clear board
    clear(state, action) {
      state.currentBoard = action.payload;
    },

    // SET CURRENT BOARD
    setCurrentBoard(state, action) {
      state.currentBoard = action.payload;
    },

    // SET CURRENT CARD
    setCurrentCard(state, action) {
      state.currentCard = action.payload;
    },

    // SET USERS LIST
    setUsersList(state, action) {
      state.usersList = action.payload;
    },

    // SET USERS LIST
    setClientsList(state, action) {
      state.clientsList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;
export const { setBoard, createColumnSuccess, setCurrentBoard, setUsersList, setClientsList } = actions;

// ----------------------------------------------------------------------

export function getNewBoard() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    // try {
    // const response = await axios.get('/api/kanban/board');
    // const board = { cards: {}, columns: {}, columnOrder: [] };
    dispatch(setBoard(initialState.currentBoard));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export function fetchBoard({ branchId, boardId }) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    // try {
    let board = initialState.currentBoard;
    if (boardId) {
      // console.log('1');
      board = await getCurrentBoard(branchId);
    } else {
      // console.log('2');
      board = await getCurrentBoard(branchId);
      // console.log('board', board);
      if (!board) {
        // console.log('3');
        board = await createBoard(branchId, initialState.currentBoard);
      }
    }
    dispatch(setBoard(board));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    // try {
    // const response = await axios.post('/api/kanban/columns/new', newColumn);

    dispatch(createColumnSuccess(newColumn));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

// ----------------------------------------------------------------------

// export function updateColumn(columnId, updateColumn) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/api/kanban/columns/update', {
//         columnId,
//         updateColumn,
//       });
//       dispatch(slice.actions.updateColumnSuccess(response.data.column));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

// export function deleteColumn(columnId) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.post('/api/kanban/columns/delete', { columnId });
//       dispatch(slice.actions.deleteColumnSuccess({ columnId }));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function clearBoard() {
  return async (dispatch) => {
    dispatch(slice.actions.clear(initialState.currentBoard));
  };
}

// ----------------------------------------------------------------------

export function persistColumn({ newColumnOrder, branchId, currentBoard }) {
  return async (dispatch) => {
    await changeColumnOrder(branchId, currentBoard, newColumnOrder);
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard({ columns, branchId, currentBoard, start, finish, draggableId }) {
  return async (dispatch) => {
    const card = currentBoard.cards[draggableId];
    await changeColumns(branchId, columns, start, finish, card);
    dispatch(slice.actions.persistCard(columns));
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId, branchId, currentBoard }) {
  return async (dispatch) => {
    const cardId = await createCard(branchId, currentBoard, card, columnId);
    // console.log('cardId', cardId);
    const newCard = {
      ...card,
      id: cardId,
    };
    dispatch(slice.actions.addTask({ card: newCard, columnId, currentBoard }));
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId, branchId, currentBoard }) {
  return async (dispatch) => {
    await deleteCard(branchId, currentBoard, cardId, columnId);
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
  };
}

// export function addElementInListCard({ name, element, currentBoard, card }) {
//   console.log('currentBoard', currentBoard);
//   const { cards } = currentBoard;
//   const newBoard = {
//     ...currentBoard,
//     cards: {
//       ...cards,
//       [card.id]: {
//         ...card,
//         [name]: [...card[name], element],
//       },
//     },
//   };
//   dispatch(setCurrentBoard(newBoard));
// }

// export function setElementInCard({ name, element, currentBoard, card }) {
//   const { cards } = currentBoard;
//   const newBoard = {
//     ...currentBoard,
//     cards: {
//       ...cards,
//       [card.id]: {
//         ...card,
//         [name]: [...card[name], element],
//       },
//     },
//   };
//   dispatch(setCurrentBoard(newBoard));
// }
