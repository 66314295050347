import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: '',
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    tax: 0,
    taxTotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    idF: '',
    billing: null,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceSaleInfo = action.payload.priceSaleInfo;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(cart.map((cartItem) => cartItem.subtotal));
      const taxTotal = sum(
        cart.map((cartItem) => (cartItem?.priceSaleInfoWithDisconunt * cartItem.quantity * cartItem.tax) / 100)
      );

      const discount = sum(cart.map((cartItem) => cartItem?.discount));
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.taxTotal = taxTotal;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = taxTotal + subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = state.checkout.cart.map((_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              articleDiscount: 0,
              quantity: _product.quantity + 1,
              articleQuantity: _product.quantity + 1,
              taxTotal: (_product.taxSaleInfo / 100) * _product.priceSaleInfo * (_product.quantity + 1),
              subtotal: (_product.quantity + 1) * _product.priceSaleInfo,
              articleTotalValue:
                (_product.articleQuantity + 1) * _product.priceSaleInfo +
                (_product.taxSaleInfo / 100) * _product.priceSaleInfo * (_product.quantity + 1),
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.taxTotal = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      console.log('payload', action);
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
            articleQuantity: product.articleQuantity + 1,
            subtotal: (product.articleQuantity + 1) * product.priceSaleInfoWithDisconunt,

            taxTotal: product.priceSaleInfoWithDisconunt * (product.articleQuantity + 1) * (product.tax / 100),
            articleTotalValue:
              (product.articleQuantity + 1) * product.priceSaleInfoWithDisconunt +
              product.priceSaleInfoWithDisconunt * (product.articleQuantity + 1) * (product.tax / 100),
            test: 'incrementar',
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      console.log('payload', action);
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
            articleQuantity: product.quantity - 1,
            subtotal: (product.articleQuantity - 1) * product.priceSaleInfoWithDisconunt,
            taxTotal: product.priceSaleInfoWithDisconunt * (product.articleQuantity - 1) * (product.tax / 100),
            articleTotalValue:
              (product.articleQuantity - 1) * product.priceSaleInfoWithDisconunt +
              product.priceSaleInfo * (product.articleQuantity - 1) * (product.tax / 100),
            test: 'decrementar',
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },
    decreaseQuantit(state, producto, parametro) {
      const productId = producto;

      const discount = producto;
      console.log('payload', parametro);
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            articleDiscount: product.articleDiscount,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },
    applyDiscountTwo(state, action) {
      const parameter = action.payload.split(';');

      const productId = parameter[1];

      let discount = parameter[0];
      if (parameter[0] === '') {
        discount = 0;
      }
      console.log(discount);

      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            priceSaleInfoWithDisconunt: product.priceSaleInfo - discount,
            articleDiscount: discount,
            subtotal: product.articleQuantity * product.priceSaleInfo - discount * product.articleQuantity,
            taxTotal: (product.priceSaleInfo - discount) * product.articleQuantity * (product.tax / 100),
            articleTotalValue:
              product.articleQuantity * product.priceSaleInfo -
              discount * product.articleQuantity +
              (product.priceSaleInfo - discount) * product.articleQuantity * (product.tax / 100),
          };
        }

        return product;
      });

      state.checkout.cart = updateCart;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  applyDiscountTwo,
  increaseQuantity,
  decreaseQuantity,
  decreaseQuantit,
  sortByProducts,
  filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products/product', {
        params: { name },
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
