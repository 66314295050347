import firebase from 'firebase/app';
import React from 'react';
import { useSelector } from 'react-redux';
import PermissionContext from './PermissionContext';
import { isFormCompleted } from '../utils/isObjectComplete';

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const PermissionProvider = ({ children }) => {
  // const { uid } = firebase.auth().currentUser;
  const { companies } = useSelector((state) => state.user.myProfile) || {};
  const { id } = useSelector((state) => state.company.currentCompany) || {};
  if (id && id !== 'null' && companies) {
    const { role } = companies[id] || {};
    const { permissions } = companies[id] || {};
    // Creates a method that returns whether the requested permission is available in the list of permissions
    // passed as parameter
    // const isAllowedTo = (permission) => permi1ssions ? Object.keys(permissions).includes(permission) : false;
    const isAllowedTo = (permission) => {
      if (role === 'owner') return true;

      return permissions
        ? Object.keys(permissions)
            .filter((permission) => permissions[permission])
            .includes(permission)
        : false;
    };
    return <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>;
  }
  const isAllowedTo = () => true;
  return <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;
