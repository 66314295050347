export const CURRENCY_OPTIONS = [
  { name: 'USD-Dollar', value: 'dolar' },
  { name: 'Euro', value: 'euro' },
  { name: 'Colon', value: 'colon' },
  { name: 'MXN', value: 'Peso Mexicano' },
  { name: 'QTZ', value: 'Quetzal' },
];

export const PAYMENT_TERMS_OPTIONS = [
  {
    name: 'Cash Payment',
    value: 'cashPayment',
  },
  {
    name: 'Fifteen Days',
    value: '15days',
  },
  {
    name: 'Thirty Days',
    value: '30days',
  },
  {
    name: 'Forty Five Days',
    value: '45days',
  },
  {
    name: 'Sixty Days',
    value: '60days',
  },
];

export const SALES_CONDITIONS = [
  {
    name: 'Contado',
    value: '01',
  },
  {
    name: 'Credito',
    value: '02',
  },
  {
    name: 'Consignación',
    value: '03',
  },
  {
    name: 'Apartado',
    value: '04',
  },
  {
    name: 'Arrendamiento con opción de compra',
    value: '05',
  },
  {
    name: 'Arrendamiento en función financiera',
    value: '06',
  },
  {
    name: 'Cobro a favor de un tercero',
    value: '07',
  },
  {
    name: 'Servicios prestados al Estado a crédito',
    value: '08',
  },
  {
    name: 'Pago del servicios prestado al Estado',
    value: '09',
  },
  {
    name: 'Otros',
    value: '99',
  },
];

export const RATE_TAXES_LIST = [
  {
    id: '01',
    label: 'Tarifa 0% (Exento)',
    value: 0,
  },
  {
    id: '02',
    label: 'Tarifa reducida 1%',
    value: 1,
  },
  {
    id: '03',
    label: 'Tarifa reducida 2%',
    value: 2,
  },
  {
    id: '04',
    label: 'Tarifa reducida 4%',
    value: 4,
  },
  {
    id: '05',
    label: 'Transitorio 0%',
    value: 0,
  },
  {
    id: '06',
    label: 'Transitorio 4%',
    value: 4,
  },
  {
    id: '07',
    label: 'Transitorio 8%',
    value: 8,
  },
  {
    id: '08',
    label: 'Tarifa general 13%',
    value: 13,
  },
];

export const GREETING_OPTIONS = [
  {
    name: 'Mr',
    value: 'Mr',
  },
  {
    name: 'Mrs',
    value: 'Mrs',
  },
  {
    name: 'Dr',
    value: 'Dr',
  },
];

export const SOCIAL_OPTIONS = [
  {
    name: 'Facebook',
    value: 'Facebook',
  },
  {
    name: 'Instagram',
    value: 'Instagram',
  },
  {
    name: 'Google',
    value: 'Google',
  },
  {
    name: 'Twitter',
    value: 'Twitter',
  },
];

export const IDENTIFICATION_TYPE_OPTIONS = [
  {
    name: 'Physical Identity Card',
    value: 'physicalIdentityCard',
  },
  {
    name: 'Legal Identity Card',
    value: 'legalIdentityCard',
  },
  {
    name: 'Without ID',
    value: 'noId',
  },
];

export const listOfProceduresCR = [
  {
    nameProcedure: 'Trámite Acto General',
    category: 'Acto General',
    detail: '<p>Detalle 1</p>',
    honoraries: '1000',
    taxSaleInfo: '5',
    accountSaleInfo: 'Cuenta de Ventas',
    stamps: [
      {
        stampId: 0,
        stampName: 'Timbre',
        stampPrice: '1000',
        stampPercentage: '1',
      },
    ],
  },
];
