import firebase from 'firebase/app';
import { setCurrentBoard } from '../redux/slices/kanban';
import { dispatch } from '../redux/store';
import { createLog } from '../redux/slices/log';

const kanbanBaseUrl = 'crm_branch';

const getCardBaseUrl = (branchId, cardId) => `${kanbanBaseUrl}/${branchId}/cards/${cardId}`;

const getColumnBaseUrl = (branchId, columnId) => `${kanbanBaseUrl}/${branchId}/columns/${columnId}`;

export async function getCurrentBoard(branchId) {
  const rootRef = await firebase
    .database()
    .ref(`/${kanbanBaseUrl}/${branchId}`)
    .once('value')
    .then((snap) => snap.val());
  return rootRef;
}

export const createBoard = async (branchId, board) => {
  const boardRef = firebase.database().ref(`/${kanbanBaseUrl}/${branchId}`);
  // const updates = {};
  // updates[`/${kanbanBaseUrl}/${branchId}/${boardId}`] = { id: boardId, ...board };
  // await firebase.database().ref().update(updates);
  // console.log('create board', boardId);
  const newBoard = {
    ...board,
    id: branchId,
  };
  await boardRef.set(newBoard);
  return newBoard;
};

// export const updateBoard = async (branchId, board) => {
//   const updates = {};
//   updates[`/${kanbanBaseUrl}/${branchId}/${board.id}`] = board;
//   await firebase.database().ref().update(updates);
//   console.log('update board', board.id);
//   return board.id;
// };

export const createCard = async (branchId, currentBoard, card, columnId) => {
  // state.currentBoard.cards[card.id] = card;
  // const boardId = currentBoard.id;
  const cardId = firebase.database().ref(`/${kanbanBaseUrl}/${branchId}`).push().key;
  const updates = {};
  const cardBaseUrl = getCardBaseUrl(branchId, cardId);
  updates[`/${cardBaseUrl}`] = { ...card, id: cardId };

  // state.currentBoard.columns[columnId].cardIds.push(card.id);
  const cardIds = [...(currentBoard.columns[columnId]?.cardIds || []), cardId];

  // const cardIds = currentBoard.columns[columnId]?.cardIds || [];
  // console.log('cardIds',cardIds)
  // cardIds.push(cardId);

  const columnBaseUrl = getColumnBaseUrl(branchId, columnId);
  updates[`/${columnBaseUrl}/cardIds`] = cardIds;

  await firebase.database().ref().update(updates);

  await createLog('project', 'create', `${cardId}`, [], card);
  return cardId;
};

export const updateCard = async (branchId, card, oldCard = []) => {
  const log = await createLog('project', 'update', `${card.id}`, oldCard, card);

  const currentBoard = await getCurrentBoard(branchId);
  // console.log('currentBoard', currentBoard);

  // const cardBaseUrl = getCardBaseUrl(branchId, card.id);
  // updates[`/${cardBaseUrl}`] = card;
  // `${kanbanBaseUrl}/${branchId}/cards/${cardId}`

  const newBoard = {
    ...currentBoard,
    cardLogs: { ...currentBoard?.cardLogs, [log]: true },
    cards: {
      ...currentBoard.cards,
      [card.id]: card,
    },
  };

  const updates = {};
  updates[`/${kanbanBaseUrl}/${branchId}`] = newBoard;

  await firebase.database().ref().update(updates);
  dispatch(setCurrentBoard(newBoard));
};

export const deleteCard = async (branchId, currentBoard, cardId, columnId) => {
  // const boardId = currentBoard.id;
  // state.currentBoard.columns[columnId].cardIds = state.currentBoard.columns[columnId].cardIds.filter(
  //   (id) => id !== cardId
  // );
  const updates = {};
  const columnBaseUrl = getColumnBaseUrl(branchId, columnId);
  const { cardIds } = currentBoard.columns[columnId];
  const newList = cardIds.filter((id) => id !== cardId);

  updates[`/${columnBaseUrl}/cardIds`] = newList;
  await firebase.database().ref().update(updates);

  // state.currentBoard.cards = omit(state.currentBoard.cards, [cardId]);
  const cardBaseUrl = getCardBaseUrl(branchId, cardId);
  const rootRef = firebase.database().ref(`/${cardBaseUrl}`);
  await rootRef.remove();
};

// newColumnOrder
export const changeColumnOrder = async (branchId, currentBoard, newColumnOrder) => {
  const boardUrl = `${kanbanBaseUrl}/${branchId}/columnOrder`;
  const updates = {};
  updates[`/${boardUrl}`] = newColumnOrder;
  await firebase.database().ref().update(updates);
};

// persistCard
export const changeColumns = async (branchId, columns, start, finish, card) => {
  await createLog('project', 'moved', `${card.id}`, { progress: start.name }, { progress: finish.name });

  const boardUrl = `${kanbanBaseUrl}/${branchId}/columns`;
  const updates = {};
  updates[`/${boardUrl}`] = columns;
  await firebase.database().ref().update(updates);
};

export const getCards = async (branchId) => {
  const data = await firebase
    .database()
    .ref(`/${kanbanBaseUrl}/${branchId}/cards`)
    .once('value')
    .then((snap) => snap.val());
  return data;
};

export const getCardById = async (branchId, cardId) => {
  const data = await firebase
    .database()
    .ref(`/${kanbanBaseUrl}/${branchId}/cards/${cardId}`)
    .once('value')
    .then((snap) => snap.val());
  return data;
};

// export const addDocumentToCard = async (branchId, cardId, doc) => {
//   const cardBaseUrl = getCardBaseUrl(branchId, cardId);
//   const currentCard = await getCardById(branchId, cardId);

//   const newCard = {
//     ...currentCard,
//     documents: [...(currentCard?.documents || []), doc],
//   };

//   const updates = {};
//   updates[`/${cardBaseUrl}`] = newCard;
//   await firebase.database().ref().update(updates);
// };

const setCard = (name, element, currentBoard, card) => {
  const { cards } = currentBoard;
  const newBoard = {
    ...currentBoard,
    cards: {
      ...cards,
      [card.id]: {
        ...card,
        [name]: { ...(card[name] || {}), [element.id]: element },
      },
    },
  };
  console.log('newBoard', newBoard);
  dispatch(setCurrentBoard(newBoard));
};

export const addFileOnCard = async (branchId, cardId, file, currentBoard, card) => {
  const cardBaseUrl = getCardBaseUrl(branchId, cardId);
  const updates = {};
  updates[`/${cardBaseUrl}/files_path/${file.id}`] = file;
  await firebase.database().ref().update(updates);

  setCard('files_path', file, currentBoard, card);
};

export const getUrlFileOfAnAttachment = async (branchId, cardId, idEncoded) => {
  const cardBaseUrl = getCardBaseUrl(branchId, cardId);

  const rootRef = await firebase
    .database()
    .ref(`/${cardBaseUrl}/files_path/${idEncoded}`)
    .once('value')
    .then((snap) => snap.val());
  return rootRef;
};
