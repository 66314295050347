import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

import mailReducer from './slices/mail';
import emailReducer from './slices/email';
import mailboxReducer from './slices/mailbox';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import clientReducer from './slices/client';
import companyReducer from './slices/company';
import productReducer from './slices/product';
import settingsReducer from './slices/settings';
import calendarReducer from './slices/calendar';
import logReducer from './slices/log';
import kanbanReducer from './slices/kanban';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings'],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  // whitelist: ['isAuthenticated']
};

const companyPersistConfig = {
  key: 'company',
  storage,
  keyPrefix: 'redux-',
  // whitelist: ['isAuthenticated']
};

const mailboxPersistConfig = {
  key: 'mailbox',
  storage,
  keyPrefix: 'redux-',
  // whitelist: ['isAuthenticated']
};

const kanbanPersistConfig = {
  key: 'kanban',
  storage,
  keyPrefix: 'redux-',
  // whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  mail: mailReducer,
  email: emailReducer,
  chat: chatReducer,
  blog: blogReducer,
  client: clientReducer,
  settings: settingsReducer,
  calendar: calendarReducer,
  log: logReducer,
  mailbox: persistReducer(mailboxPersistConfig, mailboxReducer),
  user: persistReducer(userPersistConfig, userReducer),
  company: persistReducer(companyPersistConfig, companyReducer),
  product: persistReducer(productPersistConfig, productReducer),
  kanban: persistReducer(kanbanPersistConfig, kanbanReducer),
});

export { rootPersistConfig, rootReducer };
