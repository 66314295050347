export function changeEmail(email) {
  return email.replaceAll('@', '-').replaceAll('.', '_');
}

export function getChangdEmail(email) {
  return email.replaceAll('-', '@').replaceAll('_', '.');
}

export async function encodeSha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}
