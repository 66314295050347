import React from 'react';
// import {Permission} from "../Types";

// // type PermissionContextType = {
// //     isAllowedTo: (permission: Permission) => boolean;
// // }

const defaultBehaviour = {
    isAllowedTo: () => false
}

// // Create the context
const PermissionContext = React.createContext( defaultBehaviour);

export default PermissionContext;