import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';

import { getInitialize } from '../../redux/slices/user';

JwtProvider.propTypes = {
  children: PropTypes.node
};

export default function JwtProvider({ children }) {
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isMountedRef.current) {
      dispatch(getInitialize());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return <>{children}</>;
}
